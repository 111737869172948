export default {
  generalError:
    "Es ist ein Fehler aufgetreten. Bitte versuche es später erneut oder kontaktiere den Kundenservice.",
  errors: {
    document:
      "Das Dokument konnte nicht heruntergeladen werden. Bitte versuche es später erneut.",
    documents:
      "Dokumente können gerade nicht geladen werden. Bitte versuche es später erneut.",
    contractTransfer:
      "Dieser Vertrag kann aktuell nicht übertragen werden. Bitte versuche es später erneut oder kontaktiere den Kundenservice.",
    contractTermination:
      "Dieser Vertrag kann aktuell nicht gekündigt werden. Bitte versuche es später erneut oder kontaktiere den Kundenservice.",
    meterReserved:
      "Dieser Zähler ist für einen Mieterstrom-Vertrag reserviert und kann deshalb nicht ausgewählt werden. Bitte kontaktiere den Kundenservice.",
  },
  contractUpdate: "Vertragsinformationen wurden erfolgreich aktualisiert.",
  contractDelete: "Vertrag wurde erfolgreich gelöscht.",
  customerDelete: "Kundenkonto wurde erfolgreich gelöscht.",
  bankAccount: "Bankverbindung wurde erfolgreich geändert.",
  billingAddress: "Rechnungsadresse wurde erfolgreich geändert.",
  contractPartner: {
    change: "Vertragspartner:in wurde erfolgreich geändert.",
    removal: "Vertragspartner:in wurde erfolgreich entfernt.",
  },
  meterReading: "Zählerstand wurde erfolgreich gespeichert.",
  meterReadingDelete: "Zählerstand wurde erfolgreich gelöscht.",
  email: "Es wurde ein Verifizierungslink an die angegebene E-Mail-Adresse gesendet.",
  emailPostalDelivery:
    "E-Mail-Adresse erfolgreich auf postversand@polarstern-energie.de geändert.",
  newsletter: "Newslettereinstellungen wurden erfolgreich geändert.",
  personalData: "Persönliche Daten wurden erfolgreich geändert.",
  phoneNotification: "SMS-Einstellungen wurden erfolgreich geändert.",
  phoneNumber: "Telefonnummer wurde erfolgreich geändert.",
  documentDownload: "Dokument wurde erfolgreich heruntergeladen.",
  contractTransfer: "Vertragsübertragung wurde erfolgreich eingeleitet.",
  emailAlreadyVerified: "Diese E-Mail-Adresse wurde bereits verifiziert.",
};
